 
  .img1 {
    opacity: 1;
  }
  .img2 {
    opacity: 1;
  }
  .img3 {
    opacity: 1;
  }
  .img4 {
    opacity: 1;
  }

  
  @keyframes mymove {
    0% {opacity: 1}
    20% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  .img1 {
    animation: mymove 3s forwards;
    animation-delay: 0s;
    animation-iteration-count:infinite;
  }
  
  .img2 {
    animation: mymove 3s forwards;
    animation-delay: 0.2s;
    animation-iteration-count:infinite;
  }
  
  .img3 {
    animation: mymove 3s forwards;
    animation-delay: .4s;
    animation-iteration-count:infinite;
  }
  
  .img4 {
    animation: mymove 3s forwards;
    animation-delay: .6s;
    animation-iteration-count:infinite;
  }
  